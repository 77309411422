import { NavBar, PullRefresh, List, Image, Icon,Toast } from 'vant'
export default {
  name: 'KeepAlive',
  components:{
    'van-icon':Icon,
    'van-nav-bar':NavBar,
    'van-pull-refresh':PullRefresh,
    'van-list':List,
    'van-image':Image,
  },
  data () {
    return {
      apis:{
        videoTypes:'/video/findVideoType',//获取热门推荐列表
        videos:'/video/findVideoList',//获取景点门票列表
        videoDetail:'/video/findVideo',//获取视频详情
      },
      refreshing:false,//是否刷新
      loading: true,//是否加载中
      finished: false,//数据是否已经加载完成
      params:{//查询参数
          videoType:null,
          pageIndex:1,
          pageSize: 10,
          sortName: 'play_number',
          sortOrder: 'desc'
      },
      videos:[],//视频列表
      types:[],//视频类型列表
      tab:3,
      titleList:[
        '',
        '5G看乐山',
        '智慧游乐山',
        '发现乐山',
        '域外风情',
      ],
    }
  },
  mounted() {
    const me = this;
    me.loadTypes();
    let tab = me.$route.query.tab || 3;
    me.tab = tab;
    me.typeChange(tab)
  },
  created(){

    this.getShareInfo(
      this.$global.shareTitle,
      this.$global.visitUrl,
      this.$global.shareImage,
      this.$global.shareIntro,
    );
  },
  methods: {
    // goBack(){
    //   window.android.exit();
    // },
    revert(){
      this.$router.go(-1)
    },
    loadTypes(){//获取视频类型列表
      const me = this;
      me.types = [];
      me.$get({
        url:me.apis.videoTypes
      }).then(rsp => {
        console.log(rsp);
        if(rsp && rsp.length > 0){
          let type = null;
          for(let i = 0, n = rsp.length; i < n; i++){
            if(!rsp[i].value){
                continue;
            }
            type = type ? type : rsp[i].value;
            me.types.push({
              name: rsp[i].name,
              value: rsp[i].value,
            });
          }
          // me.typeChange(type);
        }
      });
    },
    loadVideos(){//获取视频列表
      const me = this;
      me.loading = true;
      /*if(me.params.videoType == 1){
        me.$post({
          url:'http://www.zyou.cloud/bigdataapi/bigdata/api/app/videoMonitoringListChannel',
          params: {
            orgCode:'5111',
            channelCode:'ZYLS',
          }
        }).then(rsp => {
          me.loading = false;
          if(me.refreshing){
            me.videos = [];
            me.refreshing = false;
          }
          var list = rsp.results.list;
          console.log(list)

          me.videos = me.videos.concat(list);
          me.finished = true;
        });
      }*/
      // else{
        me.$get({
          url:me.apis.videos,
          params: me.params
        }).then(rsp => {
          me.loading = false;
          if(me.refreshing){
            me.videos = [];
            me.refreshing = false;
          }
          var list = rsp.data;
          if(list && list.length > 0){
            me.videos = me.videos.concat(list);
          }
          if(!list || list.length < me.params.pageSize){
            me.finished = true;
          }
        });
      // }
    },
    typeChange(v){//类型改变时执行
        const me = this;
        me.tab = v;
        me.params.pageIndex = 1;
        me.params.videoType = v;
        me.refreshing = true;
        me.finished = false;
        me.loadVideos();
    },
    onRefresh () {//下拉刷新
      const me = this;
      me.finished = false;
      me.params.pageIndex = 1;
      me.loadVideos();
    },
    onLoad(){//上拉加载
      const me = this;
      let page = me.params.pageIndex;
      page = page ? page + 1 : 1;
      me.params.pageIndex = page;
      me.loadVideos();
    },
    toDetail(key){//跳转到详情
      const me = this;
      console.log(me.params.videoType)
      if(me.params.videoType == 1){
        sessionStorage.setItem('liveList',1);
        sessionStorage.setItem('videoId',key.videoId);
        me.$router.push('/live5g/' + key.videoId);
      }
      if(me.params.videoType == 4){//趣看不一样
        sessionStorage.setItem('liveAnother',1);
        sessionStorage.setItem('videoId',key.videoId);
        me.$router.push('/liveAnother/' + key.videoId);
      }else if(me.params.videoType == '2'){
        me.$get({
          url:me.apis.videoDetail,
          params: {
            videoId:key.videoId
          }
        }).then(res => {
          me.$router.push({
            path:'/outWindow',
            query:{
              interlinkage:encodeURI(key.interlinkage)
            }
          })
          // me.$router.push('/outWindow?interlinkage=' + encodeURI(key.interlinkage));
        })
      }else if(me.params.videoType == '3'){
        me.$router.push('/liveDetail/' + key.videoId);
      }else{
        Toast('未知视频类型');
      }

    }
  }
}
